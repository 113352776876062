// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-resumes-js": () => import("./../../../src/pages/resumes.js" /* webpackChunkName: "component---src-pages-resumes-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-wrapper-js": () => import("./../../../src/pages/wrapper.js" /* webpackChunkName: "component---src-pages-wrapper-js" */)
}

